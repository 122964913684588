body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E2E8EF;
}

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: blue;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid rgb(201, 198, 194);
  /* creates padding around scroll thumb */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button {
  padding: 10px 16px;
  background: #10ac84;
  border: none;
  color: white;
  box-shadow: 0px 0px 0px 0px;
  transition: all 0.3s;
}

button:hover,
button:focus {
  box-shadow: 0px 0px 0px 0px;
}

