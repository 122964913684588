.App {
  text-align: center;
  overflow-x: hidden;
}

.searchbar {
  position: absolute;
  border-radius: 0;
  top: 25%;
  left: 3%;
  right: 3%;
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
}

.collapse {
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.main-header {
  background-color: rgb(240, 255, 241);
  max-height: 12vh;
  width: 1%;
  display: flex;
}

.app-header {
  max-height: 6vh;
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
}

.app-header-navigation {
  background-color: #007fff;
  width: 120%;
  display: flex;
  flex-direction: row;
  align-items: left;
  font-size: calc(2vmin);
  border: 2px solid white;
}

.app-header-navigation :hover {
  border-bottom: 3px solid white;
  border-bottom-left-radius: 0px;
  padding-right: 10px;
}

.app-header-link {
  padding: 10px;
  text-decoration: none;
  text-align: center;
  margin-left: 2px;
  background-color: #007fff;
  color: #ffffff;
  font-size: large;
}

.header {
  padding: 10px;
  padding-left: 5px;
  padding-right: 10.2px;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
  background-color: #007fff;
  color: #ffffff;
  font-size: large;
}

.content {
  min-height: 85vh;
  display: flex;
  background-color: #ffffff;
}

#Header_NavigationLink {
  text-align: left;
}

.activeMenu {
  padding: 10px;
  padding-left: 5px;
  padding-right: 10.2px;
  text-decoration: none;
  text-align: left;
  margin-left: 10px;
  color: black;
  font-size: large;
  background-color: #faf9f8;
  border-radius: 10px;
}

.scrollbox {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;

  /* IE 10+ */
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 5px !important;
    border-radius: 10px;
    background-color: 'transparent';
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.on-scrollbar {
  scrollbar-width: thin;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.on-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}

.on-scrollbar::-webkit-scrollbar {
  width: 10px !important;
  background-color: transparent;
  border: 3px solid #acacac;
}

.on-scrollbar::-webkit-scrollbar-thumb {
  background-color: #acacac;
  border-radius: 50px;
}

.search {
  position: relative;
  color: #aaa;
  font-size: 16px;
  display: flex;
  flex-direction: row;
}

.search input {
  border-radius: 50px;
  border: 2px #215176 solid;
  width: 900px;
  height: 53px;
  box-shadow: 0px 1px 4px 1px rgba(103, 128, 159, 1);
  font-size: 20px;
  text-indent: 10px;
  left: 50px;
}

.search input {
  text-indent: 32px;
  left: 50px;
}

.search .fa-search {
  color: #2980b9;
  float:right;
  font-size: 23px;
  position: absolute;
  top: 13px;
  left: 7px;
  width:30px;
}